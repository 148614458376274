.error-boundary {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  justify-content: center;
  align-items: center;
}
.error-boundary pre {
  color: gray;
  font-size: 14px;
}
.error-boundary button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color:brown;
  color: white;
  cursor: pointer;
}