.sidebar {
  max-width: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.25s max-width ease-in-out;
  position: fixed;
  z-index: 10;
}

.sidebar.login-page {
  display: none;
}

.sidebar.expand {
  height: 100vh;
  /* height: 100vh; */
  /* background: var(--light); */
  background: var(--primary-200);
  width: 100%;
  max-width: 16rem;
  /* box-shadow: -0.25rem 0 1rem #0001; */
  pointer-events: all;
  padding: 0.5em 0;
  opacity: 1;
  /* margin-right: 1rem; */
}

.sidebar .logo {
  opacity: 0;
  transition: 0.25s ease 0.25s;
}

.sidebar.expand .logo {
  opacity: 1;
}

.sidebar__items {
  margin: 1rem 0;
  padding: 0 0.75em;
  opacity: 0;
  transition: 0.25s ease 0.25s;
}

.sidebar__items li svg {
  width: 1.25rem;
  height: 1.25rem;
}

.sidebar.expand .sidebar__items {
  opacity: 1;
}

.sidebar__items li {
  font-weight: 500;
  padding: 0.75em 1.25em;
  cursor: pointer;
  border-radius: 0.5em;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  transition: 0.25s;
  position: relative;
}

.sidebar__items li svg {
  /* color: var(--primary); */
  margin-right: 1rem;
}

.sidebar__items li.active svg {
  color: var(--light);
}

.sidebar__items li:hover svg,
.sidebar__items li.active svg {
  fill: var(--light);
}

.sidebar__items li:hover,
.sidebar__items li.active {
  background: var(--primary);
  color: var(--light);
}

/* .sidebar__items li.active::after {
  content: "";
  height: 100%;
  width: 0.25rem;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0.5em;
  background: var(--primary);
} */

.logout-container {
  display: none;
  padding: 0 0.75em;
  width: 100%;
}
.logout-container hr {
  color: var(--light);
}
@media (min-width: 992px) {
  .sidebar {
    position: unset;
  }
}
@media (max-width: 991px) {
  .logout-container {
    display: block;
    margin-top: 3em;
  }
  .logout-container .logout-btn {
    margin-top: 1em;
    background: transparent;
    border: 2px solid var(--primary);
    border-radius: 8px;
    width: 100%;
    padding: 0.75em 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 16px;
    font-weight: 500;
    font-size: 1em;
    color: var(--primary);
  }
}
