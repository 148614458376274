@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700;800&family=Poppins:wght@200;300;400;500;600;700;800&family=Plus+Jakarta+Sans:wght@300;400;500;700;800&display=swap');

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', 'Plus Jakarta Sans', 'Segoe UI', Tahoma,
    Geneva, Verdana, sans-serif;
}

:root {
  --primary: #00296b;
  --primary-300: #f4f8fb;
  --primary-200: #f4f6fc;
  --blue-600: #001c40;
  --blue-500: #1c2a46;
  --background-main: #f5f5f5;
  --light: #fff;
  --gray: #ecefe9;
  --gray-500: #717a94;
  --gray-300: #717a94;
  --gray-200: #c0c4d1;
  --gray-100: #eaeaec;
  --success: #70e000;
  --success-200: #70e00010;
  --error: #d90429;
  --error-200: #d9042910;
  --pending: #ffc300;
  --pending-200: #ffc30010;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  /* background: var(--background-main); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style: none;
}

.main {
  display: flex;
}

.main.login-page .header,
.main.login-page .sidebar {
  display: none;
}

.main > div {
  width: 100%;
}

.container {
  width: 100%;
  padding: 1em;
}

.logo {
  display: flex;
  align-items: center;
  height: 6rem;
  padding: 0 1em;
  width: 100%;
}

.logo h2 {
  color: var(--blue-600);
  font-weight: 800;
  text-align: center;
  width: 100%;
  font-size: 1.25rem;
}

@media (min-width: 768px) {
  .logo h2 {
    font-size: 1.45rem;
  }
}

.logo span {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  margin-right: 0.75rem;
  height: 2.5rem;
  border-radius: 50%;
  background: var(--primary-200);
}

.logo span svg {
  font-size: 1.25em;
}

/* Form  */

.error {
  display: block;
  color: var(--error);
  font-size: 0.75rem;
  font-weight: 600;
  margin-top: 0.25rem;
}

.error-input {
  border: 2px solid var(--error) !important;
}

.form-row {
  margin-bottom: 1rem;
}

.form-row label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}
.form-row input {
  height: 44px;
  background: var(--background-main);
  border: 1px solid transparent;
  padding: 1em 1.25em;
  border-radius: 0.5em;
  width: 100%;
  outline: none;
}
.form-row input:focus {
  border: 1px solid var(--gray-300);
}
.form-row button {
  padding: 1em 1.25em;
  background: var(--primary);
  color: var(--light);
  font-weight: 600;
  border-radius: 0.5em;
  border: none;
  margin-top: 1rem;
  cursor: pointer;
}
.form-row button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.form-row .submit-button {
  position: relative;
}
.form-row.right {
  display: flex;
  justify-content: flex-end;
}
/* Form  */

@media (min-width: 992px) {
  .main-content {
    height: 100vh;
    overflow-y: scroll;
  }
}