.header {
  /* background: var(--primary); */
  height: 6rem;
  margin-bottom: 0.5rem;
  /* color: var(--light); */
  /* width: 100%; */
  padding: 1em;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  border-bottom: 1px solid var(--gray);
  /* border-radius: 0 0 0 0.5em; */
  justify-content: space-between;
}

.header.login-page {
  display: none;
}

.header__left {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;
}

.header__left .logo {
  padding: 0;
}
.header__left .logo h2 {
  color: var(--light);
}
.header__left .logo span {
  background: var(--primary);
}

/* .header__left .logo {

} */

.header__expand {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 2.5rem;
  margin-right: 1rem;
  height: 2.5rem;
}

.header__expand:hover {
  background-color: var(--gray-200);
  cursor: pointer;
}

.header__expand svg {
  margin-left: 0;
  font-size: 1.25rem;
}

.header__more span {
  display: block;
  margin-top: 0.25rem;
  font-size: 0.7rem;
  border-radius: 0.25em;
  text-transform: uppercase;
  background: var(--success);
  color: #fff;
  font-weight: 700;
  width: fit-content;
  margin-top: 0.75rem;
  padding: 0.5em 0.75em;
}

.header__items {
  display: flex;
  align-items: center;
}

.header__userIcon {
  height: 1.15rem;
}

.header__items li {
  margin: 0 1rem;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header__items svg {
  margin-left: 0.5rem;
}

.header__more {
  display: none;
}

.header__more svg {
  margin-left: 3rem;
}

.header__items {
  display: none;
}

.header__itemDropdown {
  position: relative;
}

.header__itemDropdown ul {
  position: absolute;
  width: calc(100% + 7rem);
  background: var(--primary-200);
  color: #000;
  z-index: 10;
  top: calc(100% + 0.5rem);
  padding: 0.5em 0;
  right: 0;
  border-radius: 0.5em;
  box-shadow: 0 0.25rem 1rem #0002;
}

.header__itemDropdown ul li {
  padding: 0.75em 1.25em;
  display: flex;
  margin: 0;
  align-items: center;
  font-size: 0.9rem;
}

.header__itemDropdown ul li.active,
.header__itemDropdown ul li:hover {
  background: #0001;
}

.header__itemDropdown ul li svg {
  margin-right: 0.5rem;
  margin-left: 0;
  width: 1rem;
  height: 1rem;
}

.header__pageDetails {
  display: flex;
  font-size: 1.75rem;
  align-items: center;
}

.header__pageDetails svg {
  margin: 0 1rem;
}

.header__pageDetails h3,
.header__pageDetails h1 {
  font-size: 1.75rem;
}

.header__pageDetails svg,
.header__pageDetails h3 {
  display: none;
}

.header__pageDetails h1 {
  font-weight: 400;
  text-transform: capitalize;
}

@media (min-width: 992px) {
  .header {
    position: unset;
  }

  .header__more {
    display: unset;
  }
  .header__items {
    display: flex;
  }

  .header__left {
    flex-direction: row;
    width: unset;
    justify-content: unset;
  }

  .header__left .logo {
    display: none;
  }

  .header__pageDetails svg,
  .header__pageDetails h3 {
    display: block;
  }
}
