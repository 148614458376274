.pagination-wrapper {
  border-top: 1px solid lightgray;
  padding-top: 12px;
  margin-left: 12px;
  display: flex;
  align-items: center;
}
.pagination-wrapper .btn-group {
  display: flex;
  column-gap: 4px;
  margin-right: 12px;
}
.pagination-wrapper .btn-group button {
  padding: 0px 6px;
  cursor: pointer;
}

.pagination-wrapper select {
  margin-left: 12px;
}