.side__slide {
  display: flex;
  width: 0;
  position: fixed;
  z-index: 1005;
  top: 0;
  right: 0;
  background: #fff;
  overflow-x: hidden;
  transition: 0.3s;
  height: calc(var(--vh, 1vh) * 100);
  box-shadow: 0px 8px 35px rgba(0, 0, 0, 0.16);
  border-left: 1px solid --var(gray);
}
.side__slide.show {
  width: 100%;
  transition: 0.25s ease-in-out;
}
.side__slide.hide {
  width: 0px;
  transition: 0.25s ease-in-out;
}
.side__slide .inner__container {
  padding: 40px 20px 20px 20px;
  height: 100%;
  width: 100%;
  position: relative;
}

.side__slide .inner__container > button {
  position: absolute;
  z-index: 10;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.3s linear;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.side__slide .inner__container > button:hover {
  background-color: #0001;
}

.side__slide .inner__container > button svg {
  width: 1.25rem;
  height: 1.25rem;
}

@media (min-width: 576px) {
  .side__slide.show {
    width: 500px;
  }
}
