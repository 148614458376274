
.iframe-wrapper {
  width: 100vw;
  min-height: calc(100vh - 300px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.iframe-wrapper iframe {
  overflow-y: scroll;
  box-shadow: none;
  border: none;
  border-radius: 8px;
}

@media (min-width: 992px) {
  .iframe-wrapper {
    width: calc(100vw - 280px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .iframe-wrapper iframe {
    box-shadow: 0px 0px 20px 1px rgb(0 0 0 / 20%);
  }
}
