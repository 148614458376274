.admin-user__actions-group {
  display: flex;
  column-gap: 24px;
}

.admin-user__actions-group svg {
  cursor: pointer;
}

.admin-user__actions-group .edit svg:hover path {
  fill: blue;
}

.admin-user__actions-group .remove svg:hover path {
  fill: red;
}

.admin-user__actions-group .user-form-title {
  margin-bottom: 1em;
}

.react-confirm-alert-body h1 {
  font-size: 27px;
  margin-bottom: 18px;
}

.react-confirm-alert-button-group {
  justify-content: flex-end;
}

@media (max-width: 40em) {
  .insight__table table tbody tr {
    border: none !important;
    padding: 0;
  }
}
