/* credit risk */
.insightDetails__contentMoneyBreakdown {
  background: var(--light);
  margin-top: 3rem;
  padding: 1em 0;
}

.insightDetails__contentMoneyBreakdown ul {
  padding: 0 1em;
  display: flex;
  border-bottom: 2px solid var(--gray);
  margin-bottom: 1rem;
}

.insightDetails__contentMoneyBreakdown ul li {
  padding: 1.5em 1em;
  display: flex;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  position: relative;
}

.insightDetails__contentMoneyBreakdown ul li.active::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: var(--primary);
  height: 0.2rem;
}

.insightDetails__contentMoneyBreakdown ul li svg {
  margin-left: 0.5rem;
}
