.login {
  display: grid;
  place-items: center;
  height: 100vh;
}

.login > div {
  max-width: 26rem;
  width: 100%;
}

.login .logo {
  width: fit-content;
  margin: 0 auto;
  margin-top: -2rem;
}

.login__container {
  background: var(--light);
  padding: 2em;
  width: 100%;
  border-radius: 0.5em;
  /* margin: 0 auto; */
}

.login__container {
  display: flex;
  flex-direction: column;
}

.login__container h1 {
  margin-bottom: 1.5rem;
}

.login__container div {
  margin-bottom: 1rem;
}

.login__container label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.login__container input {
  background: var(--background-main);
  border: none;
  padding: 1em 1.25em;
  border-radius: 0.5em;
  width: 100%;
}

.login__container button {
  padding: 1em 1.25em;
  background: var(--primary);
  color: var(--light);
  font-weight: 600;
  border-radius: 0.5em;
  border: none;
  margin-top: 1rem;
  cursor: pointer;
  width: 100%;
}
.login__container button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.submit-button {
  position: relative;
}
