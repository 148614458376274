.account-container {
  margin: 2rem 0.5rem;
}

.account-container .section {
  margin-bottom: 3rem;
}

/* .account-container .section .title {
  
} */

.account-container .section .title hr{
  border-color: #ffffff57;
}

.account-container .form-wrapper {
  max-width: 576px;
  margin: 2rem;
}

.account-container .form-wrapper .submit-button {
  position: relative;
  min-width: 210px;
}

@media(min-width: 1024px) {
  .account-container {
    margin: 2rem;
  }
}

