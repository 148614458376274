.insightDetails__content {
  padding: 1em;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.insightDetails__table {
  width: 100%;
  padding: 0.25em 1em 1em 1em;
  /* margin-left: 1rem; */
  background: var(--primary-200);
  /* padding: 1em; */
  border-radius: 0.5em;
  /* box-shadow: 0 0 2rem #0001; */
  /* overflow: hidden; */
  scrollbar-track-color: transparent;
  overflow-x: auto;
}

.insightDetails__table::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.insightDetails__table::-webkit-scrollbar-track {
  background-color: transparent;
}

.insightDetails__table::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 3rem;
}

.insightDetails__table::-webkit-scrollbar-track {
  background-color: var(--primary-200);
}

.insightDetails__contentRight {
  width: 100%;
}

.insightDetails__contentChartContainer {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
}

.insightDetails__contentChart {
  flex: 1 1 50%;
}

.insightDetails__content h3 {
  margin-bottom: 1rem;
  font-size: 1.15rem;
}

.insightDetails__content h3 svg {
  margin-left: 0.5rem;
}

/* .insightDetails__contentChart canvas {
  width: 100% !important;
} */

@media (min-width: 992px) {
  .insightDetails__contentChartContainer,
  .insightDetails__content {
    flex-direction: row;
  }
  .insightDetails__contentChart {
    margin-left: 5rem;
  }
}
