.insightDetailsData {
  background: var(--primary-200);
  padding: 1em;
  width: 100%;
  /* max-width: 15rem; */
  border-radius: 0.5em;
  margin-bottom: 1rem;
}

.insightDetailsData h3 {
  font-size: 1.15rem;
  margin-bottom: 1rem;
  font-weight: 700;
  /* white-space: nowrap; */
}

.insightDetailsData ul li {
  word-break: break-all;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.insightDetailsData ul li strong {
  margin-bottom: 0.25rem;
  font-weight: 600;
  display: block;
}

@media (min-width: 992px) {
  .insightDetailsData {
    max-width: 17rem;
    width: 100%;
    margin-right: 1rem;
  }
}
