.project__table tbody tr:hover td {
  transition: 0.4s ease-in-out;
  /* background-color: var(--secondary-400); */
}

@media (max-width: 40em) {
  .project__table table tbody tr {
    border: none !important;
    padding: 0;
  }
}
