.insightDetails__header {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  padding: 0.75em 1.5em;
  border-bottom: 1px solid var(--gray);
  font-size: 0.9rem;
  font-weight: 600;
  flex-direction: column-reverse;
}

.insightDetails__headerOptions {
  margin-top: 1rem;
  display: flex;
  overflow-x: auto;
  padding-bottom: 1rem;
}

.insightDetails__headerOptions li {
  font-size: 0.9rem;
  position: relative;
  padding: 0 0.5em;
  white-space: nowrap;
  cursor: pointer;
  width: fit-content;
}

.insightDetails__headerOptions li.active::after {
  width: 100%;
  position: absolute;
  content: '';
  height: 0.15rem;
  background: var(--primary);
  border-radius: 0.5em;
  /* bottom: -1.5em; */
  bottom: -0.85em;
  left: 0;
}

.insightDetails__headerRight {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.insightDetails__headerTotalText {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

.insightDetails__headerTotalText svg {
  margin-left: 0.5rem;
}

.insightDetails__headerRightVolume {
  display: flex;
}

.insightDetails__headerRightVolume {
  margin: 1rem 0;
  /* margin: 0 1rem; */
  border: 1px solid var(--gray);
  overflow: hidden;
  border-radius: 0.25em;
}

.insightDetails__headerRightVolume span {
  padding: 0.25em 0.5em;
  display: flex;
  align-items: center;
  background: var(--light);
  flex-direction: column;
}

.insightDetails__headerRightVolume select {
  border: 1px solid var(--gray);
  width: 4.5rem;
  border-radius: 0 0 0.25em 0.25em;
  font-weight: 600;
  cursor: pointer;
  outline: none;
}

.insightDetails__headerRightVolume span strong {
  font-size: 0.75rem;
  text-transform: uppercase;
  color: var(--gray-500);
}

.insightDetails__headerBtnPrint,
.insightDetails__headerBtnAction,
.insightDetails__headerBtnRefresh {
  width: fit-content;
  border: 1px solid var(--gray);
  display: flex;
  align-items: center;
  padding: 1em 1.25em;
  border-radius: 0.75em;
  background: transparent;
  font-weight: 600;
  justify-content: center;
  background: var(--light);
  cursor: pointer;
  position: relative;
}

.insightDetails__headerBtnAction svg,
.insightDetails__headerBtnRefresh svg {
  margin-left: 1rem;
}

.insightDetails__headerBtnAction {
  background: var(--primary);
  color: var(--light);
  margin-left: 0.5rem;
}

.insightDetails__headerBtnAction ul {
  position: absolute;
  top: calc(100% + 0.5rem);
  width: 100%;
  z-index: 2;
  box-shadow: 0 0.15rem 0.5rem #0001;
  background: #fff;
}
.insightDetails__headerBtnAction ul li {
  padding: 1em;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.insightDetails__headerBtnAction ul li:hover {
  background: #0001;
}

.insightDetails__headerBtnActionDelete {
  color: var(--error);
}
.insightDetails__headerBtnActionEdit {
  color: var(--pending);
}

@media (min-width: 535px) {
  .insightDetails__headerBtnRefresh {
    margin-left: 0.5rem;
  }
}

@media (min-width: 768px) {
  .insightDetails__header {
    flex-direction: unset;
  }

  .insightDetails__headerRightVolume {
    margin: 0 1rem;
  }

  .insightDetails__headerOptions {
    overflow-x: unset;
    padding-bottom: 0;
  }
}
