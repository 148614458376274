.insight {
  width: unset;
  margin: 1rem;
  border-radius: 0.5em;
  /* background: var(--light); */
  padding: 0;
}

.insight__subHeader {
  padding: 1em;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: var(--primary-200);
  border-radius: 1em;
  margin-top: 1rem;
}

.insight__subHeaderOptions {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, minmax(7rem, 1fr));
  /* border-radius: 0.75em; */
  /* border: 1px solid var(--gray); */
  overflow: hidden;
}

.insight__subHeaderOptions label {
  padding: 0.65em 1em;
  font-size: 0.9rem;
  font-weight: 600;
  /* border: 1px solid var(--gray); */
  color: var(--gray-500);
  position: relative;
  cursor: pointer;
  width: 100%;
  background: var(--light);
  text-align: center;
}

.insight__subHeaderOptions label:hover,
.insight__subHeaderOptions label.active {
  /* color: #000; */
  color: #fff;
  background: var(--primary);
  /* box-shadow: inset 0 0 0.75rem #0001; */
}

.insight__subHeaderOptions input {
  position: absolute;
  visibility: hidden;
}

.insight__subHeaderOptions #select-project {
  height: 40px;
  border: none;
  padding: 0 8px;
  border-radius: 6px;
}

.insight__subHeaderOptions #select-project:focus {
  outline: none;
}

.insight__subHeaderSearch {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 1rem 0;
}

.insight__subHeaderSearch form {
  padding: 0.65em 1em;
  margin-left: 0.5rem;
  /* border: 1px solid var(--gray); */
  border-radius: 0.5em;
  background: var(--light);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.insight__subHeaderSearch form input {
  border: none;
  font-weight: 500;
  width: 100%;
  font-size: 0.9rem;
  outline: none;
}

.insight__top {
  /* box-shadow: 0 0 2rem #0001; */
  border-radius: 0.5em;
  margin-bottom: 2rem;
}

.insight__table {
  background: var(--primary-200);
  padding: 1em;
  /* padding: 1em; */
  border-radius: 1em;
  /* box-shadow: 0 0 2rem #0001; */
  /* overflow: hidden; */
  overflow-x: auto;
  scrollbar-track-color: transparent;
}

.insight__table::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.insight__table::-webkit-scrollbar-track {
  background-color: transparent;
}

.insight__table::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 3rem;
}

.insight__table::-webkit-scrollbar-track {
  background-color: var(--primary-200);
}

@media (min-width: 768px) {
  .insight__subHeaderOptions {
    grid-template-columns: repeat(4, minmax(8rem, 1fr));
  }
}

@media (min-width: 992px) {
  .insight {
    margin: 2rem;
  }

  .insight__subHeader {
    padding: 0 1em;
    flex-wrap: nowrap;
  }

  .insight__subHeaderOptions {
    width: unset;
  }

  .insight__subHeaderSearch {
    width: 100%;
    max-width: 23rem;
  }
}
