table {
  border-collapse: separate !important;
  border-spacing: 0;
  width: 100%;
}

table th {
  /* background: var(--primary); */
  min-width: 8rem;
  font-size: 0.9rem;
  padding: 0.75em 0.75em;
  font-weight: 700;
  position: sticky;
  color: var(--gray-500);
  white-space: nowrap;
  top: 0;
  text-align: left;
}

table table th {
  background: var(--primary-300);
  color: #000;
}

table th:first-child {
  width: fit-content;
  min-width: unset;
}

/* table td:first-child {
  border-right: 1px solid var(--gray);
} */

table table td:first-child {
  border-right: none;
}

table td:first-child svg {
  color: var(--gray-500);
  display: block;
  margin: auto;
}

table tr {
  margin-bottom: 1rem;
}

table tr:first-child td:first-child {
  border-radius: 1em 0 0 0;
}
table tr:first-child td:last-child {
  border-radius: 0 1em 0 0;
}
table tr:last-child td:last-child {
  border-radius: 0 0 1em 0;
}
table tr:last-child td:first-child {
  border-radius: 0 0 0 1em;
}

table th span {
  width: 1.25rem;
  /* display: flex; */
  height: 1.25rem;
  border-radius: 3em;
  margin-bottom: -0.25rem;
  /* background: var(--primary-200); */
}

table th div {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding: 0.5em 0;
  justify-content: flex-end;
}

table th div svg {
  margin-right: 0.5rem;
}

table td {
  font-size: 0.9rem;
  padding: 1em;
  background: var(--light);
  /* border-bottom: 1px solid var(--gray); */
  font-weight: 500;
  /* cursor: pointer; */
}

.table__chevron {
  cursor: pointer;
}

table td .table__data {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 0.25em 1em;
  border-radius: 0.5em;
  font-weight: 600;
  font-size: 0.8rem;
  /* font-size: 0.7rem; */
  border-radius: 0.25em;
  text-transform: uppercase;
  /* width: fit-content; */
  /* padding: 0.5em 0.75em; */
  padding: 0.5em 0;
}

table td span svg {
  margin-right: 0.5rem;
}

table td .table__data.assigned,
table td .table__data.completed {
  justify-content: center;
  color: var(--success);
  padding: 0.75em 0;
  border-radius: 0.75em;
  /* color: var(--light); */
  background: var(--success-200);
}

table td .table__data.error {
  color: var(--error);
  justify-content: center;
  padding: 0.75em 0;
  border-radius: 0.75em;
  /* color: var(--light); */
  background: var(--error-200);
}

table td .table__data.unassigned,
table td .table__data.in-progress {
  color: var(--pending);
  justify-content: center;
  padding: 0.75em 0;
  border-radius: 0.75em;
  /* color: var(--light); */
  background: var(--pending-200);
}

/* table tr td:first-child,
table tr th:first-child {
  position: sticky;
  left: 0;
  z-index: 10;
} */

table tr td:first-child {
  background: #fff;
}

table td.clickable {
  cursor: pointer;
  color: var(--clr-primary);
}

/* table tr th:first-child {
  z-index: 11;
} */

table table th {
  color: var(--gray-500);
}

table table th,
table table td {
  font-size: 0.8rem;
}

.table__pagination span {
  margin: 0 1rem;
  font-size: 0.8rem;
}

.table__pagination select {
  font-size: 0.8rem;
  padding: 0.5em 0.75em;
}

.table__pagination button {
  font-size: 0.8rem;
  padding: 0.5em 1em;
  background: var(--clr-primary);
  color: #fff;
  border: none;
  border-radius: 0.25em;
  margin: 0 0.25rem;
}

.table__pagination button:hover {
  color: #fff;
}

.table__view > td {
  padding: 0;
}

@media (min-width: 768px) {
  table th {
    min-width: 10rem;
  }

  table th:first-child {
    width: fit-content;
    min-width: unset;
  }
}

.num-row {
  text-align: right!important;
  padding-right: 36px;
}
