.user-form {
  background: var(--light);
  width: 100%;
  border-radius: 0.5em;
  /* margin: 0 auto; */
}

.user-form {
  display: flex;
  flex-direction: column;
}

.user-form h1 {
  margin-bottom: 1.5rem;
}


.user-form .form-row {
  margin-bottom: 1rem;
}

.user-form label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.user-form .form-row > input {
  height: 44px;
}

.user-form .form-row .error-msg {
  color: red;
  font-size: 0.8em;
}

.user-form input {
  background: var(--background-main);
  border: 1px solid transparent;
  padding: 1em 1.25em;
  border-radius: 0.5em;
  width: 100%;
  outline: none;
}

.user-form input:focus {
  border: 1px solid var(--gray-300);
}

.user-form .select-project .css-13cymwt-control {
  min-height: 44px;
  border-radius: 0.5rem;
}
.user-form .select-project .css-t3ipsp-control {
  min-height: 44px;
  border-radius: 0.5rem;
  border-color: var(--gray-300) !important;
  box-shadow: none;
}

.user-form button {
  position: relative;
  padding: 1em 1.25em;
  background: var(--primary);
  color: var(--light);
  font-weight: 600;
  border-radius: 0.5em;
  border: none;
  margin-top: 1rem;
  cursor: pointer;
  width: 100%;
}
.user-form button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.submit-button {
  position: relative;
}
