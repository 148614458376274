.admin-container {
  margin: 30px;
  padding: 0 15px;
}

.admin-container .new-user {
  margin-bottom: 18px;
}

.admin-container .new-user .submit-button {
  padding: 1em 1.25em;
  background: var(--primary);
  color: var(--light);
  font-weight: 600;
  border-radius: 0.5em;
  border: none;
  margin-top: 1rem;
  cursor: pointer;
  width: 100%;
  max-width: 180px;
}

.admin-container .new-user .user-form-container {
  padding: 20px 0;
  background-color: var();
}
.admin-container .new-user .user-form-container .select-project * {
  margin-bottom: 0;
}
.admin-container .new-user .user-form-container .error * {
  margin-bottom: 0;
}
.admin-container .new-user .user-form-container .error {
  margin: 0;
  outline: 2px solid red;
  border-radius: 5px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .admin-container {
    margin: 30px 0px;
    padding: 0 15px;
  }
}
