.insightDetailsInfo {
  padding: 1em 0;
  display: flex;
  flex-wrap: wrap;
}

.insightDetailsInfo h3 {
  margin-bottom: 1rem;
  font-size: 1.15rem;
}

.insightDetailsInfo > div {
  overflow-x: auto;
}

.insightDetailsInfo__incomeDetail {
  margin: 1rem 0;
  display: flex;
  /* flex-wrap: wrap; */
  /* flex-wrap: wrap; */
  overflow-x: auto;
}

.insightDetailsInfo__incomeDetail.employer {
  margin-right: 1rem;
}

.insightDetailsInfo__income {
  background: var(--primary-200);
  padding: 1.5em 1em;
  flex-direction: column;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  justify-content: center;
  margin-right: 0.5rem;
  text-align: center;
  margin-top: 0.5rem;
  /* border: 0.5px solid var(--gray); */
  /* margin: .5rem 0; */
}

/* .insightDetailsInfo__income.employer-info {
  text-align: left;
  align-items: flex-start;
} */

.insightDetailsInfo__income strong {
  font-size: 1.2rem;
}

.insightDetailsInfo__income h4 {
  font-size: 0.9rem;
  color: var(--gray-500);
  margin: 0.5rem 0 0.25rem 0;
  font-weight: 600;
}

.insightDetailsInfo__income h4 svg path {
  fill: var(--gray-500);
}

.insightDetailsInfo__income h4 svg,
.insightDetailsInfo h3 svg {
  margin-left: 0.5rem;
  margin-bottom: -0.15rem;
}

@media (min-width: 768px) {
  .insightDetailsInfo__incomeDetail,
  .insightDetailsInfo > div {
    overflow-x: unset;
  }
}

@media (min-width: 992px) {
  .insightDetailsInfo {
    flex-wrap: nowrap;
  }

  .insightDetailsInfo__income {
    flex-shrink: unset;
  }

  .insightDetailsInfo > div {
    width: 100%;
  }

  .insightDetailsInfo__income {
    width: 100%;
  }
}
