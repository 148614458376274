.insight__header {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  padding: 1em 0;
  border-bottom: 1px solid var(--gray);
  font-size: 0.9rem;
  font-weight: 600;
  flex-direction: column-reverse;
}

.insight__headerOptions {
  margin-top: 1rem;
}

.insight__headerOptions li {
  font-size: 0.9rem;
  position: relative;
  padding: 0 1.5em;
  cursor: pointer;
  width: fit-content;
}

.insight__headerOptions li::after {
  width: 100%;
  position: absolute;
  content: '';
  height: 0.15rem;
  background: var(--primary);
  border-radius: 0.5em;
  /* bottom: -1.5em; */
  bottom: -0.85em;
  left: 0;
}

.insight__headerRight {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.insight__headerTotalText {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

.insight__headerTotalText svg {
  margin-left: 0.5rem;
}

.insight__headerRightVolume {
  display: flex;
}

.insight__headerRightVolume {
  margin: 1rem 0;
  /* margin: 0 1rem; */
  border: 1px solid var(--gray);
  overflow: hidden;
  border-radius: 0.75em;
}

.insight__headerRightVolume span {
  padding: 0.5em 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  background: var(--light);
  flex-direction: column;
}

.insight__headerRightVolume select {
  border: none;
  border-left: 1px solid var(--gray);
  width: 4.5rem;
  border-radius: 0 0 0.25em 0.25em;
  font-weight: 600;
  cursor: pointer;
  outline: none;
}

.insight__headerRightVolume span strong {
  font-size: 0.75rem;
  color: var(--gray-200);
  font-weight: 600;
}

.insight__headerBtnRefresh {
  width: fit-content;
  border: 1px solid var(--gray);
  display: flex;
  align-items: center;
  padding: 1em 1.25em;
  border-radius: 0.75em;
  background: transparent;
  font-weight: 600;
  justify-content: center;
  background: var(--light);
  cursor: pointer;
}

.insight__headerBtnRefresh svg {
  margin-left: 1rem;
}

@media (min-width: 535px) {
  .insight__headerBtnRefresh {
    margin-left: 0.5rem;
  }
}

@media (min-width: 768px) {
  .insight__header {
    flex-direction: unset;
  }

  .insight__headerOptions li::after {
    bottom: -2em;
  }

  .insight__headerRightVolume {
    margin: 0 1rem;
  }
}
